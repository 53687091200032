import * as React from 'react'

import { Color } from 'cesium'

const colors = [
  Color.RED,
  Color.BLUE,
  Color.CHOCOLATE,
  Color.GREEN,
  Color.CORAL,
  Color.DARKORANGE,
  Color.DARKSLATEBLUE,
  Color.DEEPSKYBLUE,
  Color.CRIMSON,
  Color.PURPLE,
  Color.SALMON,
  Color.DARKSLATEGRAY,
  Color.DARKGREEN,
  Color.ROSYBROWN,
  Color.CADETBLUE,
  Color.BLUEVIOLET,
  Color.ORANGE,
  Color.BROWN,
  Color.CORNFLOWERBLUE,
  Color.DARKOLIVEGREEN,
  Color.BLACK,
  Color.DARKORCHID,
  Color.SLATEGREY,
  Color.DARKMAGENTA,
]

const PilotsContext = React.createContext({
  pilots: [],
  tracked: [],
  hidden: [],
})

const shuffleColors = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

const SHUFFLED_COLORS = shuffleColors(colors)

export const PilotContextProvider = ({ children }) => {
  const [pilots, setPilots] = React.useState([])
  const [tracked, setTracked] = React.useState(null)
  const [hidden, setHidden] = React.useState([])

  const onPilotsChange = (pilots) => {
    const random = Math.floor(Math.random() * pilots.length)
    setPilots(
      pilots.map((p, index) => ({
        ...p,
        color: SHUFFLED_COLORS[index % SHUFFLED_COLORS.length],
        // isBayraktar: p.PilotId === 99,
        isBayraktar: random === index,
      }))
    )
  }

  const onTrackedChange = (id) => setTracked(tracked !== id ? id : null)

  const onHiddenChange = (id) => {
    if (hidden.includes(id)) {
      setHidden(hidden.filter((x) => x !== id))
    } else {
      setHidden([...hidden, id])
    }
  }

  const toggleAll = (show) => {
    setHidden(show ? [] : pilots.map((p) => p.PilotId))
  }

  const clearPilotsState = () => {
    setPilots([])
    setTracked(null)
    setHidden([])
  }

  return (
    <PilotsContext.Provider
      value={{
        pilots,
        tracked,
        hidden,
        toggleAll,
        onPilotsChange,
        onTrackedChange,
        onHiddenChange,
        clearPilotsState,
      }}
    >
      {children}
    </PilotsContext.Provider>
  )
}

export const usePilotsContext = () => React.useContext(PilotsContext)
