import { useState, useEffect, useMemo } from 'react'

import { ClockRange, JulianDate } from 'cesium'
import { Clock, useCesium } from 'resium'

import { useStoreContext } from '../../contexts/store'

const ClockWrapper = () => {
  const { viewer } = useCesium()
  const [currentTime, setCurrentTime] = useState(JulianDate.now())

  const { trackPath, trackStatus, start, finish } = useStoreContext()

  const hasTrackFinished = useMemo(() => trackStatus !== '', [trackStatus])

  useEffect(() => {
    if (trackPath && start && finish) {
      if (hasTrackFinished) {
        setCurrentTime(start.clone())
      }

      viewer.timeline.zoomTo(start, finish)
    }
  }, [start, finish, trackPath])

  return (
    <Clock
      canAnimate
      startTime={start ? start.clone() : JulianDate.now()}
      currentTime={currentTime}
      stopTime={finish ? finish.clone() : JulianDate.now()}
      multiplier={hasTrackFinished ? 30 : 1}
      shouldAnimate
      // This stupid stuff breaks the app
      // clockRange={hasTrackFinished ? ClockRange.LOOP_STOP : ClockRange.UNBOUNDED}
    />
  )
}

export default ClockWrapper
