import { useState, useEffect, createContext, useContext } from 'react'

const GodModeContext = createContext({
  iAMGod: false,
})

export const GodModeContextProvider = ({ children }) => {
  const [iAMGod, setIAMGod] = useState(false)

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('iamgod') && urlParams.get('iamgod') === 'sure') {
      setIAMGod(true)
    }
  }, [])

  return (
    <GodModeContext.Provider
      value={{
        iAMGod
      }}
    >
      {children}
    </GodModeContext.Provider>
  )
}

export const useGodModeContext = () => useContext(GodModeContext)
