import { createContext, useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'

const SidebarContext = createContext({
  sidebarCollapsed: isMobile,

  setSidebarCollapsed: () => null
})

export const SidebarContextProvider = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(isMobile)

  return (
    <SidebarContext.Provider
      value={{
        sidebarCollapsed,
        setSidebarCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebarContext = () => useContext(SidebarContext)
