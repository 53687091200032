export const sortTracks = (tracks) => {
  const keys = Object.keys(tracks)

  const sorted = keys.sort((a, b) => {
    const aArr = a.split('_')
    const bArr = b.split('_')

    if (aArr[1] === bArr[1]) {
      if (aArr[2] === 'PM') {
        return -1
      }

      return 1
    }

    return Number(bArr[1]) - Number(aArr[1])
  })

  return sorted
}
