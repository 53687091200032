import { useState, memo, useMemo, useCallback } from 'react'

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Chip,
} from '@material-ui/core'

import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EventIcon from '@material-ui/icons/Event'

import { startCase } from 'lodash'
import { sortTracks } from '../../../utils/tracks'

import { useStoreContext } from '../../../contexts/store'

import classes from './Sidebar.module.scss'

const TrackList = () => {
  const { trackPath, onSetTrackPath, tracks } = useStoreContext()

  const [tracksCollapsed, setTracksCollapsed] = useState(true)

  const sortedTracks = useMemo(() => sortTracks(tracks), [tracks])

  const trackGetter = useCallback((track) => tracks[track], [tracks])

  const trackStatus = useCallback(
    (track) => {
      const obj = trackGetter(track)
      return obj.Status
    },
    [trackGetter]
  )

  return (
    <>
      {/* Flights */}
      <ListItem
        button
        onClick={() => setTracksCollapsed(!tracksCollapsed)}
        classes={{
          root: classes.listItem,
        }}
        style={{
          marginTop: 10,
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <EventIcon fontSize="inherit" style={{ color: '#fff' }} />
        </ListItemIcon>
        <ListItemText primary="Flights" />
        {tracksCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={tracksCollapsed} timeout="auto">
        {sortedTracks.length === 0 && (
          <div className={classes.noData}>No Flights</div>
        )}
        {sortedTracks.map((trackKey, index) => {
          const track = trackGetter(trackKey)
          const status = trackStatus(trackKey)
          return (
            <ListItem
              key={index}
              selected={trackKey === trackPath}
              dense
              classes={{
                root: classes.listItem,
                selected: classes.selected,
              }}
              button
              onClick={() => onSetTrackPath(trackKey)}
            >
              <ListItemText
                primary={track?.Name || startCase(trackKey)}
                secondary={startCase(trackKey).replace('Tracks ', '')}
              />

              <ListItemSecondaryAction>
                {status === '' ? (
                  <Chip
                    label={`${track.Goals?.length || 0} goals`}
                    size="small"
                    color="primary"
                  />
                ) : (
                  <Chip
                    label={status}
                    size="small"
                    style={{
                      color: '#fff',
                      backgroundColor:
                        status === 'Finished' ? '#33CA7F' : '#f50057',
                    }}
                  />
                )}
                <IconButton
                  variant="text"
                  size="small"
                  style={{ marginLeft: 7 }}
                >
                  {trackKey !== trackPath ? (
                    <StarBorderIcon fontSize="small" />
                  ) : (
                    <StarIcon fontSize="small" />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </Collapse>
    </>
  )
}

export default memo(TrackList)
