import { useMemo, memo } from 'react'

import {
  Cartesian2,
  Cartesian3,
  Color,
  Ellipsoid,
  HeightReference,
  VerticalOrigin,
  ImageMaterialProperty,
  NearFarScalar
} from 'cesium'
import { Entity, CylinderGraphics } from 'resium'

import { useStoreContext } from '../../contexts/store'
import { useMarkersContext } from '../../contexts/markers'

const Goals = () => {
  const { tracks, trackPath } = useStoreContext()
  const { displayGoals } = useMarkersContext()

  const goals = useMemo(() => tracks[trackPath]?.Goals || [], [trackPath])

  return (
    <>
      {displayGoals && goals.map((goal, index) => (
        <Entity
          key={index}
          name={goal.Name}
          position={Cartesian3.fromDegrees(
            goal.Longitude,
            goal.Latitude,
            150,
            Ellipsoid.WGS84
          )}
          label={{
            text: goal.Name,
            font: '12pt "Roboto", sans-serif',
            verticalOrigin: VerticalOrigin.CENTER,
            pixelOffset: new Cartesian2(0, -50),
            fillColor: Color.BLACK,
            backgroundColor: Color.WHITE,
            showBackground: true,
            heightReference: HeightReference.CLAMP_TO_GROUND,
            scaleByDistance: new NearFarScalar(1000, 1, 3e3, 0.5),
          }}
        >
          <CylinderGraphics
            length={1.5}
            topRadius={goal.Size || 25}
            bottomRadius={goal.Size || 25}
            material={new ImageMaterialProperty({
              image: require('../../assets/goal.png'),
              transparent: true,
            })}
            outline
            outlineColor={Color.ORANGE.withAlpha(0.25)}
            heightReference={HeightReference.CLAMP_TO_GROUND}
          />
        </Entity>
      ))}
    </>
  )
}

export default memo(Goals)
