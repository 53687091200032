import { useEffect, useState, useRef, Fragment, useCallback, memo, useMemo } from 'react'

import { useStoreContext } from '../../contexts/store'
import { useMarkersContext } from '../../contexts/markers'
import { useGodModeContext } from '../../contexts/godmode'

import {
  Cartesian2,
  Cartesian3,
  SampledPositionProperty,
  JulianDate,
  Color,
  Ellipsoid,
  VerticalOrigin,
  HeightReference,
  VelocityOrientationProperty,
  NearFarScalar,
  ColorBlendMode,
  ExtrapolationType,
} from 'cesium'
import { Entity, CylinderGraphics } from 'resium'

import Baloon from '../../assets/CesiumBalloon.glb'
import Bayraktar from '../../assets/Bayraktar.glb'

const PilotTrack = ({ pilot }) => {
  const { displayMarkers } = useMarkersContext()
  const { iAMGod } = useGodModeContext()

  const ref = useRef(new SampledPositionProperty())

  const {
    trackPath,
    trackStatus,
    start,
    finish,
    getPilotsInitialData,
    latestPoints,
    removeUsedPoints,
  } = useStoreContext()

  const [points, setPoints] = useState([])
  const [filteredPoints, setFilteredPoints] = useState([])

  useEffect(() => {
    if (points.length > 0) {
      const filtered = iAMGod
        ? [...points]
        : points.filter((x) => x.Timestamp + 600000 <= new Date().getTime())
      setFilteredPoints(filtered)

      ref.current.addSamples(
        filtered.map((p) => new JulianDate.fromDate(new Date(p.Timestamp))),
        filtered.map((p) => p.position)
      )
    }
  }, [points])

  const setInitialPoints = useCallback(
    (data) => {
      if (!data) {
        return null
      }

      const ppts = data
        .sort((a, b) => a.Timestamp - b.Timestamp)
        .map((x) => ({
          ...x,
          position: Cartesian3.fromDegrees(
            x.Longitute,
            x.Latitude,
            x.Altitude,
            Ellipsoid.WGS84
          ),
        }))

      setPoints(ppts)
    },
    [start, finish, pilot]
  )

  useEffect(() => {
    setInitialPoints(getPilotsInitialData(pilot.PilotId))

    if (ref.current) {
      ref.current.forwardExtrapolationType = ExtrapolationType.HOLD
      ref.current.backwardExtrapolationType = ExtrapolationType.HOLD
    }
  }, [])

  useEffect(() => {
    if (latestPoints.length) {
      const pilotsPoints = latestPoints
        .filter((p) => p.PilotId === pilot.PilotId)
        .sort((a, b) => a.Timestamp - b.Timestamp)
        .map((point) => {
          const position = Cartesian3.fromDegrees(
            point.Longitute,
            point.Latitude,
            point.Altitude,
            Ellipsoid.WGS84
          )

          ref.current.addSample(
            JulianDate.fromDate(new Date(point.Timestamp)),
            position
          )

          return {
            ...point,
            position,
          }
        })

      setPoints([...points, ...pilotsPoints])
      removeUsedPoints(
        pilot.PilotId,
        pilotsPoints.map((x) => x.Timestamp)
      )
    }
  }, [trackPath, pilot, points, latestPoints, removeUsedPoints])

  const getPilotInitials = useCallback(() => {
    const { PilotId, PilotName } = pilot

    const names = PilotName.split(' ')
    const firstName = names[0]?.split('')
    const lastName = names[1]?.split('')

    return `#${PilotId}: ${firstName[0] + lastName[0]}`
  }, [])

  // const getMarkers = filteredPoints.filter((x) => x.DroppedMarker)

  // const getCartesian3Positions = filteredPoints.map((x) =>
  //   Cartesian3.fromDegrees(x.Longitute, x.Latitude, x.Altitude, Ellipsoid.WGS84)
  // )

  const latestPoint = useMemo(() => filteredPoints[filteredPoints.length - 1], [filteredPoints])

  const hasFinished = trackStatus !== ''

  return (
    <Fragment>
      {filteredPoints.length > 0 && (
        <Entity
          label={{
            text: `${getPilotInitials()} Start`,
            font: '8pt "Roboto", sans-serif',
            verticalOrigin: VerticalOrigin.CENTER,
            heightReference: HeightReference.CLAMP_TO_GROUND,
            backgroundColor: pilot.color,
            showBackground: true,
            scaleByDistance: new NearFarScalar(1000, 1, 1.5e3, 0.5),
          }}
          position={filteredPoints[0].position}
          point={{
            pixelSize: 10,
            color: pilot.color.withAlpha(0.25),
            outlineColor: pilot.color,
            outlineWidth: 2,
          }}
        />
      )}

      <Entity
        polyline={{
          material: pilot.color.withAlpha(0.5) || Color.BLUE,
          width: 3,
          positions: filteredPoints.map((x) =>
            Cartesian3.fromDegrees(
              x.Longitute,
              x.Latitude,
              x.Altitude,
              Ellipsoid.WGS84
            )
          ),
        }}
      />

      {latestPoint && (
        <>
          <Entity
            id={pilot.PilotId}
            name="Current Position"
            position={
              hasFinished ? ref.current : latestPoint.position
            }
            orientation={
              pilot.isBayraktar
                ? new VelocityOrientationProperty(ref.current)
                : undefined
            }
            model={{
              allowPicking: false,
              uri: pilot.isBayraktar ? Bayraktar : Baloon,
              silhouetteColor: pilot.color,
              silhouetteSize: 1,
              color: pilot.color,
              colorBlendMode: ColorBlendMode.MIX,
              colorBlendAmount: pilot.isBayraktar ? 0 : 0.7,
              ...(pilot.isBayraktar
                ? {
                    scale: 0.01,
                    minimumPixelSize: 75,
                    maximumScale: 75,
                  }
                : {
                    minimumPixelSize: 150,
                    maximumScale: 75,
                  }),
            }}
          />
          <Entity
            position={latestPoint.position}
            label={{
              text: pilot.PilotName,
              font: '8pt "Roboto", sans-serif',
              verticalOrigin: VerticalOrigin.CENTER,
              pixelOffset: new Cartesian2(0, pilot.isBayraktar ? -50 : 25),
              backgroundColor: pilot.color,
              showBackground: true,
            }}
            point={{
              pixelSize: 20,
              color: pilot.color,
            }}
          />
        </>
      )}
      {displayMarkers &&
        filteredPoints
          .filter((x) => x.DroppedMarker)
          .map((point, index) => (
            <Entity
              key={index + '_dropped-marker'}
              position={point.position}
              label={{
                text: `M.${point.DroppedMarker}`,
                font: '9pt "Roboto", sans-serif',
                verticalOrigin: VerticalOrigin.CENTER,
                heightReference: HeightReference.CLAMP_TO_GROUND,
                backgroundColor: pilot.color.withAlpha(0.75),
                showBackground: true,
                scaleByDistance: new NearFarScalar(1.5e3, 1, 2.5e3, 0.5),
              }}
            >
              <CylinderGraphics
                length={100}
                topRadius={20}
                bottomRadius={1}
                material={pilot.color.withAlpha(0.7)}
                outline
                outlineColor={pilot.color}
                heightReference={HeightReference.CLAMP_TO_GROUND}
              />
            </Entity>
          ))}
    </Fragment>
  )
}

export default memo(PilotTrack)
