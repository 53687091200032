import { useState, Fragment, memo } from 'react'

import VisibilityIcon from '@material-ui/icons/Visibility'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Button,
} from '@material-ui/core'

import { usePilotsContext } from '../../../contexts/pilots'

import classes from './Sidebar.module.scss'

const PilotsList = () => {
  const { pilots, tracked, hidden, toggleAll, onHiddenChange } =
    usePilotsContext()
  const [pilotsCollapsed, setPilotsCollapsed] = useState(true)

  const [showAll, setShowAll] = useState(true)

  const isHidden = (id) => hidden.includes(id)

  const onToggleAll = () => {
    const status = !showAll

    setShowAll(status)
    toggleAll(status)
  }

  return (
    <>
      {/* Pilots */}
      <ListItem
        button
        onClick={() => setPilotsCollapsed(!pilotsCollapsed)}
        dense
        classes={{
          root: classes.listItem,
        }}
        style={{
          marginTop: 10,
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <PeopleAltIcon fontSize="inherit" style={{ color: '#fff' }} />
        </ListItemIcon>
        <ListItemText primary="Pilots" />
        {!pilotsCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={!pilotsCollapsed} timeout="auto">
        {pilots.length === 0 ? (
          <div className={classes.noData}>No Pilots</div>
        ) : (
          <ListItem style={{ paddingBottom: 20 }}>
            <ListItemSecondaryAction>
              <Button onClick={onToggleAll}>
                {showAll ? 'Hide' : 'Show'} All
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {pilots.map((pilot) => (
          <Fragment key={pilot.PilotId}>
            <ListItem
              selected={pilot.PilotId === tracked}
              dense
              classes={{
                root: classes.listItem,
                selected: classes.selected,
              }}
            >
              <ListItemIcon classes={{ root: classes.iconRoot }}>
                <div
                  className={classes.pilotColor}
                  style={{ backgroundColor: pilot.color.toCssColorString() }}
                ></div>
              </ListItemIcon>
              <ListItemText primary={`${pilot.PilotId}. ${pilot.PilotName}`} />

              <ListItemSecondaryAction>
                {/* <Button
              variant="text"
              color="primary"
              size="small"
              endIcon={
                !isTracked(pilot.PilotId) ? (
                  <StarBorderIcon fontSize="small" />
                ) : (
                  <StarIcon fontSize="small" />
                )
              }
              onClick={() => onTrackedChange(pilot.PilotId)}
            >
              {isTracked(pilot.PilotId) ? 'Untrack' : 'Track'}
            </Button> */}
                <IconButton
                  variant="text"
                  size="small"
                  color={isHidden(pilot.PilotId) ? 'primary' : 'default'}
                  onClick={() => onHiddenChange(pilot.PilotId)}
                  style={{ marginLeft: 7 }}
                >
                  {!isHidden(pilot.PilotId) ? (
                    <VisibilityIcon fontSize="small" />
                  ) : (
                    <VisibilityOffIcon fontSize="small" />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Fragment>
        ))}
      </Collapse>
    </>
  )
}

export default memo(PilotsList)
