import { useEffect, useRef, useCallback } from 'react'

import { createWorldTerrain, Cartographic, Color, Ion, createOsmBuildings } from 'cesium'
import { Viewer } from 'resium'
import CesiumNavigation from '@znemz/cesium-navigation'

import { useStoreContext } from '../../contexts/store'

Ion.defaultAccessToken = process.env.REACT_APP_ION_TOKEN

const terrainProvider = createWorldTerrain({
  key: process.env.REACT_APP_ION_TOKEN,
})

const ViewerWrapper = ({ children }) => {
  const ref = useRef(null)

  const { trackPath, initialCameraPosition } = useStoreContext()

  const initOptions = useCallback(() => {
    setTimeout(() => {
      if (
        ref &&
        ref.current &&
        ref.current.cesiumElement &&
        ref.current.cesiumElement.scene
      ) {
        if (!ref.current.cesiumElement.cesiumNavigation) {
          CesiumNavigation(ref.current.cesiumElement, {
            defaultResetView: Cartographic.fromDegrees(
              initialCameraPosition ? initialCameraPosition.Longitute : 0,
              initialCameraPosition ? initialCameraPosition.Latitude : 0,
              10000
            ),
            enableCompass: true,
            enableZoomControls: true,
            enableDistanceLegend: true,
            enableCompassOuterRing: true,
          })
        }

        const scene = ref.current.cesiumElement.scene

        scene.debugShowFramesPerSecond = process.env.NODE_ENV === 'development'

        // isDesktop && process.env.NODE_ENV !== 'development' && scene.primitives.add(createOsmBuildings())
        // scene.primitives.add(createOsmBuildings())
        scene.globe.depthTestAgainstTerrain = false
        scene.globe.baseColor = Color.GREEN
        scene.globe.maximumScreenSpaceError = 3
        scene.skyBox.show = false
        scene.screenSpaceCameraController.minimumZoomDistance = 100
        scene.screenSpaceCameraController.maximumZoomDistance = 300000
      }
    }, 1000)
  }, [initialCameraPosition])

  useEffect(() => {
    if (trackPath) {
      initOptions()
    }
  }, [trackPath])

  return (
    <Viewer
      ref={ref}
      showRenderLoopErrors
      infoBox={false}
      baseLayerPicker={false}
      homeButton={false}
      sceneModePicker={false}
      navigationHelpButton={false}
      geocoder={false}
      maximumRenderTimeChange={Infinity}
      timeline
      animation
      fullscreenButton={false}
      shadows={false}
      terrainShadows={false}
      skyAtmosphere={false}
      resolutionScale={1.2}
      shouldAnimate
      terrainProvider={terrainProvider}
      useBrowserRecommendedResolution
    >
      <>{children}</>
    </Viewer>
  )
}

export default ViewerWrapper
