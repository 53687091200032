import * as React from 'react'

const MarkersContext = React.createContext({
  displayMarkers: true,
  displayGoals: true,
})

export const MarkerContextProvider = ({ children }) => {
  const [displayMarkers, setDisplayMarkers] = React.useState(true)
  const [displayGoals, setDisplayGoals] = React.useState(true)

  return (
    <MarkersContext.Provider
      value={{
        displayMarkers,
        setDisplayMarkers,
        displayGoals,
        setDisplayGoals,
      }}
    >
      {children}
    </MarkersContext.Provider>
  )
}

export const useMarkersContext = () => React.useContext(MarkersContext)
