import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useStoreContext } from '../../../contexts/store'

import { isDesktop } from 'react-device-detect'

import classes from './Sidebar.module.scss'

const EventSelector = ({ sidebarCollapsed }) => {
  const { selectedEvent, events, eventGetter, onSetSelectedEvent } =
    useStoreContext()

  return (
    <div className={classes.selection}>
      {(!isDesktop && sidebarCollapsed) && (<h5 style={{ color: '#000' }}>{eventGetter && eventGetter.name}</h5>) }
      <Select
        variant="outlined"
        value={selectedEvent}
        onChange={(e) => onSetSelectedEvent(e.target.value)}
        fullWidth
        color="primary"
        style={{
          display: !sidebarCollapsed ? 'block' : 'none',
        }}
      >
        {events.map((event, index) => (
          <MenuItem key={index} value={event.id}>
            {event.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default EventSelector
