import { memo } from 'react'

import { useMarkersContext } from '../../../contexts/markers'

import { FormControlLabel, Checkbox } from '@material-ui/core'
import classes from './Sidebar.module.scss'

const Controls = () => {
  const { displayMarkers, setDisplayMarkers, displayGoals, setDisplayGoals } =
    useMarkersContext()

  const handleMarkerDisplayChange = (event) => {
    setDisplayMarkers(event.target.checked)
  }

  const handleGoalsDisplayChange = (event) => {
    setDisplayGoals(event.target.checked)
  }

  return (
    <div className={classes.markerDisplay}>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={displayMarkers}
              onChange={handleMarkerDisplayChange}
              size="small"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="Show Pilot Markers"
          labelPlacement="start"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={displayGoals}
              onChange={handleGoalsDisplayChange}
              size="small"
              classes={{ root: classes.checkboxRoot }}
            />
          }
          label="Show Goals"
          labelPlacement="start"
        />
      </div>
    </div>
  )
}

export default memo(Controls)
