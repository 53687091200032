import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react'

import { JulianDate } from 'cesium'

import { sortTracks } from '../utils/tracks'

const StoreContext = createContext({
  events: [],
  selectedEvent: null,
  start: null,
  finish: null,
  trackPath: '',
  tracks: {},
  initialData: [],
  initialCameraPosition: null,
  latestPoint: [],

  onSetEvents: () => {},
  onSetSelectedEvent: () => {},

  onSetTrackPath: () => {},
  onSetInitialData: () => {},
  onAddLatestPoint: () => {},

  getPilotsInitialData: () => {},
  removeUsedPoints: () => {},
  onChangeTrackPath: () => {},
})

export const StoreContextProvider = ({ children }) => {
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState('')

  const [trackPath, setTrackPath] = useState('')
  const [tracks, setTracks] = useState({})
  const [trackStatus, setTrackStatus] = useState('')

  const [start, setStart] = useState(null)
  const [finish, setFinish] = useState(null)

  const [initialData, setInitialData] = useState({})
  const [initialCameraPosition, setInitialCameraPosition] = useState(null)
  const [latestPoints, setLatestPoints] = useState([])

  const onSetEvents = useCallback((events, initialEvent) => {
    setEvents(events)

    if (initialEvent) {
      const evt = events.find((event) => event.id === initialEvent)

      setSelectedEvent(initialEvent)
      setTracks(evt.tracks)
      onFindLatestTrack(evt.tracks)
      getEventLaunchCoordinates(evt.launchCoordinates)
    }
  }, [])

  const onSetSelectedEvent = useCallback(
    (id) => {
      setSelectedEvent(id)
      const evt = events.find((event) => event.id === id)

      if (evt) {
        setTracks(evt.tracks)
        onFindLatestTrack(tracks)
        getEventLaunchCoordinates(evt.launchCoordinates)
      }
    },
    [events]
  )

  const getEventLaunchCoordinates = (launchCoordinates) => {
    const coordinates = launchCoordinates.replace(/\s/g, '')?.split(',')

    const cameraPos = {
      Longitute: Number(coordinates[1]),
      Latitude: Number(coordinates[0]),
    }
    setInitialCameraPosition(cameraPos)
  }

  const onFindLatestTrack = (tracks) => {
    const sorted = sortTracks(tracks)

    const latestTrack = sorted[0]

    if (latestTrack) {
      setTrackPath(latestTrack)
      setTrackStatus(tracks[latestTrack].Status)
    }
  }

  const onSetTrackPath = (path) => {
    setTrackPath(path)
    setTrackStatus(tracks[path].Status)
  }
  const onChangeTrackPath = (track) => {
    setTrackPath(track)
    setTrackStatus(tracks[track].Status)
  }

  const onSetInitialData = (data) => setInitialData(data)
  const onAddLatestPoint = (point) =>
    setLatestPoints([...latestPoints, ...point])

  const onSetStart = (date) =>
    setStart(
      JulianDate.addSeconds(JulianDate.fromDate(date), -30, new JulianDate())
    )
  const onSetFinish = (date) =>
    setFinish(
      JulianDate.addSeconds(JulianDate.fromDate(date), 30, new JulianDate())
    )

  const getPilotsInitialData = useCallback(
    (id) => initialData[id],
    [initialData]
  )

  const removeUsedPoints = (id, timestamps) =>
    setLatestPoints(
      latestPoints.filter(
        (x) => x.PilotId === id && !timestamps.includes(x.Timestamp)
      )
    )

  const eventGetter = useMemo(
    () => events.find((x) => x.id === selectedEvent),
    [events, selectedEvent]
  )
  const clearStoreState = () => {
    setInitialData({})
    setLatestPoints([])
    setStart(null)
    setFinish(null)
  }

  return (
    <StoreContext.Provider
      value={{
        events,
        selectedEvent,
        eventGetter,
        start,
        finish,
        trackPath,
        tracks,
        trackStatus,
        initialData,
        initialCameraPosition,
        latestPoints,
        onSetEvents,
        onSetSelectedEvent,
        onSetTrackPath,
        onSetInitialData,
        onAddLatestPoint,

        getPilotsInitialData,
        removeUsedPoints,
        onChangeTrackPath,

        onSetStart,
        onSetFinish,

        clearStoreState,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export const useStoreContext = () => useContext(StoreContext)
