import * as React from 'react'

import { isDesktop } from 'react-device-detect'

import { useSidebarContext } from '../../../contexts/sidebar'
import { useGodModeContext } from '../../../contexts/godmode'
import { useStoreContext } from '../../../contexts/store'

import classes from './Header.module.scss'

const Header = () => {
  const { sidebarCollapsed } = useSidebarContext()
  const { eventGetter } = useStoreContext()
  const { iAMGod } = useGodModeContext()

  return (
    <div
      className={[
        classes.headerContainer,
        sidebarCollapsed ? classes.full : classes.minimized,
      ].join(' ')}
    >
      <a
        className={classes.title}
        href="https://supportukrainenow.org/"
        target="_blank"
        rel="noreferrer"
      >
        <code>Slava Ukraini</code>
      </a>
      <div className={classes.subtitle}>
        Live Balloon Tracker{' '}
        <sub>
          <strong>{process.env.REACT_APP_VERSION}</strong>
        </sub>
      </div>

      <div style={{ marginLeft: 'auto' }}>
        {(sidebarCollapsed && isDesktop) && <h5>{eventGetter && eventGetter.name}</h5>}
        {iAMGod && (
          <code>
            <strong style={{ color: '#FFD633' }}>God Mode</strong>
          </code>
        )}
      </div>
    </div>
  )
}

export default Header
