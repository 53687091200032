import { useEffect, useCallback } from 'react'

import PilotTrack from './components/Cesium/PilotTrack'
import { database } from './firebase'
import { ref, onValue, query, limitToLast } from 'firebase/database'

import { usePilotsContext } from './contexts/pilots'
import { useStoreContext } from './contexts/store'

import { Cartesian3 } from 'cesium'
import { useCesium } from 'resium'

const Container = () => {
  const { viewer } = useCesium()
  const { pilots, hidden, onPilotsChange } = usePilotsContext()
  const { selectedEvent, trackPath, initialCameraPosition, onAddLatestPoint } =
    useStoreContext()

  useEffect(() => {
    if (initialCameraPosition) {
      const { Longitute = 0, Latitude = 0 } = initialCameraPosition
      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(Longitute, Latitude, 5e3),
        duration: 0,
      })
    }
  }, [initialCameraPosition])

  useEffect(() => {
    if (trackPath) {
      initRealtimeDatabase()
    }
  }, [trackPath])

  const initRealtimeDatabase = useCallback(() => {
    if (!trackPath) {
      return
    }

    const trackRef = query(
      ref(database, `/${selectedEvent}/${trackPath}`),
      limitToLast(1)
    )

    onValue(trackRef, (snapshot) => {
      const data = snapshot.val()

      if (!data) {
        return null
      }

      if (Object.values(data).length === 1) {
        const item = Object.values(data)[0]

        if (item) {
          const pilotsMapped = pilots.map((p) => p.PilotId)

          if (!pilotsMapped.includes(item.PilotId)) {
            onPilotsChange([
              ...pilots,
              { PilotId: item.PilotId, PilotName: item.PilotName },
            ])
          }

          onAddLatestPoint([item])
        }
      }
    })
  }, [])

  return (
    <>
      {pilots
        .filter((pilot) => !hidden.includes(pilot.PilotId))
        .map((pilot) => (
          <PilotTrack key={pilot.PilotId} pilot={pilot} />
        ))}
    </>
  )
}

export default Container
