import { useState, memo, useEffect } from 'react'

import Container from './Container'
import Header from './components/Layout/Header/Header'
import Sidebar from './components/Layout/Sidebar/Sidebar'

import Viewer from './components/Cesium/Viewer'
import Clock from './components/Cesium/Clock'

import Loader from './components/Loader/Loader'

import Controls from './components/Controls/Controls'

import { usePilotsContext } from './contexts/pilots'
import { useStoreContext } from './contexts/store'
import { useSidebarContext } from './contexts/sidebar'

import { groupBy } from 'lodash'

import { database } from './firebase'
import { ref, child, get } from 'firebase/database'
import Goals from './components/Cesium/Goals'

const App = () => {
  const { onPilotsChange, clearPilotsState } = usePilotsContext()

  const {
    trackPath,
    onSetStart,
    onSetFinish,
    selectedEvent,
    onSetEvents,
    onSetInitialData,
    clearStoreState,
  } = useStoreContext()

  const { sidebarCollapsed } = useSidebarContext()

  const [loading, setLoading] = useState(false)
  const [iOS, setIOS] = useState(false)

  useEffect(() => {
    getiOS()
    getInitialData()
  }, [])

  useEffect(() => {
    if (trackPath) {
      clearPilotsState()
      clearStoreState()
      getTrackData()
    }
  }, [trackPath])

  const getiOS = () => {
    setIOS(!!navigator.userAgent.match(/(iPhone)/))
  }

  const getInitialData = async () => {
    setLoading(true)

    const dbRef = ref(database)

    get(child(dbRef, '/events')).then((snapshot) => {
      const values = snapshot.val()

      onSetEvents(values, values.length ? values[0].id : null)
    })
  }

  const getTrackData = async () => {
    setLoading(true)

    const dbRef = ref(database)

    get(child(dbRef, `/${selectedEvent}/${trackPath}`)).then((snapshot) => {
      const values = snapshot.val()

      if (values === null || values === undefined) {
        setLoading(false)
        return
      }

      const filtered = Object.values(values)
        .filter((d) => d.Latitude !== 0 || d.Longitute !== 0)
        .sort((a, b) => a.Timestamp - b.Timestamp)

      const data = groupBy(filtered, (x) => x.PilotId)

      const pilots = Object.keys(data).map((d) => {
        const pilot = data[d].find((dt) => dt.PilotId === Number(d))

        const { PilotId, PilotName } = pilot
        return {
          PilotId,
          PilotName,
        }
      })

      if (filtered.length) {
        const startPoint = filtered[0]
        const finishPoint = filtered[filtered.length - 1]

        if (startPoint && finishPoint) {
          const startTimestamp = startPoint.Timestamp
          const finishTimestamp = finishPoint.Timestamp

          onSetStart(new Date(startTimestamp))
          onSetFinish(new Date(finishTimestamp))
        }
      }

      onPilotsChange(pilots)
      onSetInitialData(data)

      setLoading(false)
    })
  }

  return (
    <div className={['appContainer', iOS ? 'app_iOS' : 'not_iOS'].join(' ')}>
      <div className="content">
        <Header />

        <div
          className={[
            'mapContainer',
            sidebarCollapsed ? 'mapContainer_full' : 'mapContainer_minimized',
          ].join(' ')}
        >
          <Controls />
          {loading ? (
            <Loader />
          ) : (
            <Viewer>
              <Clock />
              <Goals />
              <Container />
            </Viewer>
          )}
          {/* <AdContainer /> */}
        </div>

        <Sidebar />
      </div>
    </div>
  )
}

export default memo(App)
