import { useState } from 'react'

import { IconButton, Collapse } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import classes from './Controls.module.scss'

const Controls = () => {
  const [show, setShow] = useState(false)

  return (
    <>
      <div className={classes.container}>
        <span className={classes.buttonContainer}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setShow(!show)}
          >
            <HelpOutlineIcon fontSize="small" color="inherit" />
          </IconButton>
        </span>
        <Collapse in={show} timeout="auto" unmountOnExit>
          <div className={classes.innerContainer}>
            <span>Mouse Controls</span>
            <ul>
              <li>
                Mousewheel - <strong>Zoom</strong>
              </li>
              <ul>
                <li>
                  Click and drag - <strong>Move</strong>
                </li>
                <li>
                  Ctrl + Click and drag - <strong>Orbit</strong>
                </li>
              </ul>
            </ul>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default Controls
