import { memo } from 'react'
import { List, IconButton } from '@material-ui/core'
import { isDesktop } from 'react-device-detect'

import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import PilotsList from './PilotsList'
import TrackList from './TrackList'

import classes from './Sidebar.module.scss'
import Controls from './Controls'
import EventSelector from './EventSelector'
import { useSidebarContext } from '../../../contexts/sidebar'

const Sidebar = () => {
  const { sidebarCollapsed, setSidebarCollapsed } = useSidebarContext()

  return (
    <div
      className={[
        classes.sidebarContainer,
        !sidebarCollapsed ? classes.open : classes.closed,
      ].join(' ')}
    >
      <div className={classes.title}>
        <EventSelector sidebarCollapsed={sidebarCollapsed} />
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        >
          <span className={classes.iconWrapper}>
            <MenuOpenIcon />
          </span>
        </IconButton>
      </div>

      <Controls />

      {!sidebarCollapsed ? (
        <>
          <div className={classes.content}>
            <List dense disablePadding>
              <TrackList />

              <PilotsList />
            </List>
          </div>
        </>
      ) : isDesktop ? (
        <div className={classes.menuPlaceholder}>
          {'MENU'.split('').map((letter, index) => (
            <div key={index}>{letter}</div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default memo(Sidebar)
